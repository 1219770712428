import Config from "../../utils/Config";

const { serverUrl } = Config;

const Url = {
    WORK_LIST: serverUrl + "/showWorkForInvite",

    MY_ASSESS: serverUrl + "/showMyInvite",

    SUBMIT_ASSESS: serverUrl + "/submitInvite",

    VIDEO_INFO: serverUrl + "/getVideoInfo",

    IMAGE_INFO: serverUrl + "/getImageUrl",
};

export default Url;
