import React from "react";

enum StateEnum {
    LOADING = 1,
    SUCCESS = 2,
    ERROR = 3,
    UPLOADING = 4,
}

interface IProps {
    state: StateEnum;
    children?: React.ReactNode;
    className?: string;
    text?: string;
}

const LOADING_TXT = {
    [StateEnum.LOADING]: "加载中，请稍候...",
    [StateEnum.ERROR]: "网络异常，请刷新重试",
    [StateEnum.UPLOADING]: "文件上传中",
};

const Loading: React.SFC<IProps> = ({
    state,
    text,
    children,
    className = "",
}: IProps) => {
    if (state !== StateEnum.SUCCESS) {
        return (
            <div
                className={`ui-loading ${className}`}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {text ? text : LOADING_TXT[state]}
            </div>
        );
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
};

export default Loading;
