import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";

const Lab: FunctionComponent<{}> = () => {
    return (
        <Layout
            background={BACKGROUND_IMG}
            title="声明文件"
            className="declare"
        >
            <p>
                每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位
            </p>
            <p>
                每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位
            </p>
            <p>
                每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位
            </p>
            <p>每一位每一位每一位每一位每一位每一位每一位每一位每一位每一位</p>
        </Layout>
    );
};

export default Lab;
