import React from "react";
import Flex, { FlexConst } from "../../ui/Flex";

import "./Header.scss";

import LOGO_IMG from "./img/logo.png";
import SLOGAN_IMG from "./img/slogan.png";

interface IProps {
    children?: React.ReactNode;
    logo?: boolean;
}

const Header: React.SFC<IProps> = ({ children, logo = false }: IProps) => {
    return (
        <header className="ui-header">
            <div className="head-in">
                <Flex ratio={[1, 1]} cross={FlexConst.cross.top}>
                    <div className="slogan">
                        <img src={SLOGAN_IMG} alt="" />
                    </div>
                    {logo ? (
                        <div className="logo">
                            <img src={LOGO_IMG} alt="" />
                        </div>
                    ) : null}
                </Flex>

                {children}
            </div>
        </header>
    );
};
export default Header;
