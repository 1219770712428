import React from "react";
import BaseComponent from "../../ui/BaseComponent";
import PluginHttp from "../../http/PluginHttp";
import Url from "../Assess/Const";

import "./AlVideoPlayer.scss";

export enum IAlPlayerEncrypt {
    ENCRYPT = 1,
    DECRYPT = 0,
}

export enum IAlPlayerEncryptType {
    ALIYUN = "AliyunVoDEncryption",
    HLS = "HLSEncryption",
}

export interface IAlPlayerOptions {
    PlayURL: string;
    // 视频流清晰度定义 FD（流畅）LD（标清）SD（高清）HD（超清）
    Definition: Array<string>;
    // 视频流长度，单位秒
    Duration: number;
    //视频流是否加密流
    Encrypt: IAlPlayerEncrypt;
    // 视频流加密类型
    EncryptType?: IAlPlayerEncryptType;
}

interface IProps {
    data: any;

    willPlay?: () => boolean;
}

interface IState {
    videoInfo: any;
    imageUrl: string;
    long: boolean;
}

export default class AlVideoPlayer extends BaseComponent<IProps, IState> {
    private player: any = null;

    state = {
        imageUrl: "",
        videoInfo: null,
        long: false,
    };

    componentDidMount() {
        const { data } = this.props;
        if (data.videoType) {
            data.videoType === "img" ? this.getImageUrl() : this.getVideoInfo();
        }
    }

    componentWillUnmount() {
        this.player && this.player.pause();
    }

    getVideoInfo = () => {
        const { data } = this.props;
        PluginHttp.ajax({
            url: Url.VIDEO_INFO,
            headers: {
                videoId: data.videoId,
                courseId: data.courseId,
                subId: data.subId,
            },
        })
            .then((res) => {
                if (res.returnCode === "10000") {
                    this.setState(
                        {
                            videoInfo: res,
                        },
                        () => {
                            this.init();
                        }
                    );
                }
            })
            .catch((e: Error) => {
                console.log("获取素材详情失败", e);
            });
    };

    getImageUrl = () => {
        const { data } = this.props;
        PluginHttp.ajax({
            url: Url.IMAGE_INFO,
            headers: {
                videoId: data.videoId,
            },
        })
            .then((res) => {
                if (res.returnCode === "10000") {
                    this.setState(
                        {
                            imageUrl: res.workUrl,
                        },
                        () => {
                            this.init();
                        }
                    );
                }
            })
            .catch((e: Error) => {
                console.log("获取素材详情失败", e);
            });
    };

    init = () => {
        const { videoInfo } = this.state;

        const Player = (window as any).Aliplayer;

        if (!Player) {
            window.alert("视频SDK加载异常，请刷新重试");
            return;
        }

        if (videoInfo) {
            const {
                // PlayAuth,
                PlayInfoList = [],
                VideoBase = {},
            } = videoInfo as any;
            const PlayInfo = PlayInfoList[0] || {};

            this.player = new Player({
                id: "aliplayer",
                width: "100%",
                height: "100%",
                autoplay: false,
                preload: true,
                definition: PlayInfo.definition,
                // encryptType: EncryptType,
                rePlay: false,

                //支持播放地址播放,此播放优先级最高
                source: PlayInfo.playURL,
                cover: VideoBase.CoverURL,

                //播放方式二：点播用户推荐
                // vid : '1e067a2831b641db90d570b6480fbc40',
                // playauth : 'ddd',
            });
            console.log("------", Player, this.player, videoInfo);

            this.player.on("play", this.handlePlay);
        }
    };

    handlePlay = () => {
        const { willPlay } = this.props;
        if (willPlay && !willPlay()) {
            this.player.pause();
        }
    };

    onLoadImg = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
        const target = e.target as HTMLImageElement;
        this.setState({ long: target.height > target.width });
    };

    render() {
        const { data } = this.props;
        const { imageUrl, long } = this.state;

        return (
            <div className="ui-video" id="aliplayer">
                {data.videoType === "img" ? (
                    <img
                        className={long ? "long-img" : ""}
                        src={imageUrl}
                        alt=""
                        onLoad={this.onLoadImg}
                    />
                ) : null}
            </div>
        );
    }
}
