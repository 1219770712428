import React from "react";
import { RouteComponentProps } from "react-router";
import BaseComponent from "../../ui/BaseComponent";
import Flex, { FlexConst } from "../../ui/Flex";
import Menu from "../common/Menu";
import Footer from "../common/Footer";
import Header from "../common/Header";
import WXLogin from "./WXLogin";

import MAIN_IMG from "./img/main.png";

import "./Login.scss";

type LoginProps = {
    setAlitaState: (param: any) => void;
    auth: any;
} & RouteComponentProps;

class Login extends BaseComponent<LoginProps> {
    state = {
        height: "auto",
    };

    handleSubmit = (e: React.FormEvent) => {};

    render() {
        const docHeight = window.innerHeight || window.screen.availHeight;

        return (
            <section className="p-login" style={{ minHeight: docHeight }}>
                <Header logo />
                <section>
                    <Flex
                        className="content-in"
                        ratio={[1, 0]}
                        cross={FlexConst.cross.top}
                    >
                        <div className="main">
                            <img src={MAIN_IMG} alt="" />

                            <Menu type="index" />
                        </div>

                        <WXLogin />
                    </Flex>
                </section>

                <Footer />
            </section>
        );
    }
}

export default Login;
