import React from "react";
import BaseComponent from "../BaseComponent";
import Button from "../Button";

import "./Popup.scss";

interface IPopupProps {
    type: "small" | "middle" | "large" | "xl";

    title?: string;

    buttons?: Array<string>;

    content?: any;

    onConfirmListener?: (index: number, button: any) => void;

    onButtonListener?: (index: number, button: any) => void;

    onClose?: () => void;

    className?: string;
}

export default class Popup<P> extends BaseComponent<IPopupProps & P, any> {
    static defaultProps = {
        type: "small",
        buttons: ["取消", "确认"],
        close: false,
    };

    onButtonClick = (index: number, button: any): void => {
        const { onButtonListener } = this.props;
        onButtonListener && onButtonListener(index, button);
    };

    renderChildren(): React.ReactNode {
        return this.props.children;
    }

    renderButton = (): React.ReactNode => {
        const { buttons } = this.props;
        if (buttons && buttons.length > 0) {
            return (
                <div className="react-pop-buttons">
                    {buttons.map((button: any, index: any) => {
                        return (
                            <Button
                                className={
                                    "button " +
                                    (buttons.length > 1 ? "" : "single-btn")
                                }
                                key={index}
                                onClick={this.onButtonClick.bind(
                                    this,
                                    index,
                                    button
                                )}
                            >
                                {button}
                            </Button>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    render() {
        const classMap: { [key: string]: string } = {
            small: "s",
            middle: "m",
            large: "l",
            xl: "xl",
        };
        const { className, content, title, type } = this.props;
        return (
            <div
                className={`react-popup react-popup-${classMap[type]} ${className}`}
            >
                {title ? <div className="react-pop-head">{title}</div> : null}
                <div className="react-pop-body">
                    {type === "small" && content ? (
                        <div className="react-pop-content">
                            <div className="ver-middle">{content}</div>
                        </div>
                    ) : (
                        <div className="react-pop-content">
                            {content || this.renderChildren()}
                        </div>
                    )}

                    {this.renderButton()}
                </div>
            </div>
        );
    }
}
