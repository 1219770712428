import React from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import Page from "./Page";
import Official from "./Official";
import Error from "./pages/Error/Error";

export default () => (
    <Router>
        <Switch>
            <Route
                exact
                path="/"
                render={() => <Redirect to="/wechatweb/index" push />}
            />
            <Route path="/wechatweb" component={Page} />
            <Route path="/official" component={Official} />
            <Route path="/error/:desc" component={Error} />
        </Switch>
    </Router>
);
