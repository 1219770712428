import React, { FunctionComponent, ReactNode } from "react";
import Layout from "../common/Layout";
import Flex from "../../../ui/Flex";

import "./index.scss";
import BACKGROUND_IMG from "./img/lab_bg.png";

import HUXC_IMG from "./img/leader/huxc.png";
import CHENXW_IMG from "./img/leader/chenxw.png";
import XINGP_IMG from "./img/leader/xingp.png";
import YUANSC_IMG from "./img/leader/yuansc.png";
import MAYA_IMG from "./img/leader/maya.png";

import TEACHER_Ati_Maier from "./img/teacher/Ati_Maier.png";
import TEACHER_Catherine_Ruane from "./img/teacher/Catherine_Ruane.png";
import TEACHER_Claudia_Chaseling from "./img/teacher/Claudia_Chaseling.png";
import TEACHER_Norton_WR from "./img/teacher/Norton_WR.png";
import TEACHER_Miwa_Elharar from "./img/teacher/Miwa_Elharar.png";
import TEACHER_Eun_Hyung_Kim from "./img/teacher/Eun_Hyung_Kim.png";
import TEACHER_Christina_Massey from "./img/teacher/Christina_Massey.png";
import TEACHER_Maki_Itanami from "./img/teacher/Maki_Itanami.png";
import TEACHER_Takehiro_Nikai from "./img/teacher/Takehiro_Nikai.png";
import TEACHER_Trevor_Guthrie from "./img/teacher/Trevor_Guthrie.png";
import TEACHER_CHEN_DONG_FAN from "./img/teacher/chendongfan.png";
import TEACHER_XU_TANG_WEI from "./img/teacher/xutangwei.png";
import TEACHER_Erika_Harrsch from "./img/teacher/Erika_Harrsch.png";
import TEACHER_Kakyoung_Lee from "./img/teacher/Kakyoung_Lee.png";
import TEACHER_Resa_Blatman from "./img/teacher/Resa_Blatman.png";
import TEACHER_ZHANG_JING from "./img/teacher/zhangjing.png";
import TEACHER_Katrin_Brause from "./img/teacher/Katrin_Brause.png";

const LEADER_LIST = [
    {
        name: "胡小冲（中）",
        image: HUXC_IMG,
        info: [
            "多所艺术院校校外硕导，清华大学美术学院硕士",
            "原中国广告联合总公司互动媒体总监",
            "泛美联合机构创办人",
            "UAD艺术实验室联合创始人、研发负责人",
        ],
    },
    {
        name: "陈筱薇（美）",
        image: CHENXW_IMG,
        info: [
            "美国知名魔幻现实主义画家",
            "驻扎麻省唯一荣获美国优秀艺术教师奖的华人，被授予美国国家教育勋章",
            "波士顿艺术博物馆附设学院艺术家客座讲师，获选纽约艺术基金Artadia的驻市艺术家",
            "UAD艺术实验室联合创始人、研发负责人",
        ],
    },
    {
        name: "邢鹏（中）",
        image: XINGP_IMG,
        info: [
            "原北京高阳捷迅信息技术有限公司运维创新组负责人",
            "原北京一九付支付科技有限公司运维组部门主管兼信息安全负责人",
            "UAD艺术实验室联合创始人、系统负责人",
        ],
    },
    {
        name: "苑少纯（中）",
        image: YUANSC_IMG,
        info: [
            "清华大学美术学院教师",
            "原中央美术学院教师",
            "UAD艺术实验室学术负责人",
        ],
    },
    {
        name: "Maya Meen（泰）",
        image: MAYA_IMG,
        info: ["美国波士顿优秀插画艺术家、艺术教师", "UAD艺术实验室学术负责人"],
    },
];

const TEACHER_LIST = [
    {
        name: "Ati Maier（德）",
        image: TEACHER_Ati_Maier,
    },
    {
        name: "Catherine Ruane（美）",
        image: TEACHER_Catherine_Ruane,
    },
    {
        name: "Claudia Chaseling（德）",
        image: TEACHER_Claudia_Chaseling,
    },
    {
        name: "Norton WR（美）",
        image: TEACHER_Norton_WR,
    },
    {
        name: "Miwa Elharar（日）",
        image: TEACHER_Miwa_Elharar,
    },
    {
        name: "Eun Hyung Kim（韩）",
        image: TEACHER_Eun_Hyung_Kim,
    },
    {
        name: "Christina Massey（美）",
        image: TEACHER_Christina_Massey,
    },
    {
        name: "Maki Itanami（日）",
        image: TEACHER_Maki_Itanami,
    },
    {
        name: "Takehiro Nikai（日）",
        image: TEACHER_Takehiro_Nikai,
    },
    {
        name: "Trevor Guthrie（英）",
        image: TEACHER_Trevor_Guthrie,
    },
    {
        name: "陈东帆（中）",
        image: TEACHER_CHEN_DONG_FAN,
    },
    {
        name: "许唐玮（中国台湾）",
        image: TEACHER_XU_TANG_WEI,
    },
    {
        name: "Erika Harrsch（美）",
        image: TEACHER_Erika_Harrsch,
    },
    {
        name: "Kakyoung Lee（韩）",
        image: TEACHER_Kakyoung_Lee,
    },
    {
        name: "Resa Blatman（美）",
        image: TEACHER_Resa_Blatman,
    },
    {
        name: "张静（中）",
        image: TEACHER_ZHANG_JING,
    },
    {
        name: "Katrin Brause（德）",
        image: TEACHER_Katrin_Brause,
    },
];

const Lab: FunctionComponent<{}> = () => {
    function renderLeader(item: any, index: number): ReactNode {
        return (
            <Flex className="leader-item" key={index} ratio={[0, 1]}>
                <img src={item.image} alt={item.name} />
                <div className="leader-detail">
                    <h4 className="name">{item.name}</h4>
                    {item.info.map((text: string, i: number) => {
                        return <p key={i}>{text}</p>;
                    })}
                </div>
            </Flex>
        );
    }

    function renderTeacher(item: any, index: number): ReactNode {
        return (
            <div className="teacher-item" key={index}>
                <img src={item.image} alt={item.name} />
                <div className="name">{item.name}</div>
            </div>
        );
    }

    return (
        <Layout
            background={BACKGROUND_IMG}
            title="关于实验室"
            className="about-lab"
        >
            <div className="desc">
                <p>
                    <span>自</span>
                    2011年成立开始， 实验室吸引了众多来自各国的职业艺术家，
                    并始终专注于国际艺术前
                    沿发展与学术艺术的专业体系脚本研发。同有着强烈绘画爱好的青少儿进行沟通、分享、讨
                    论与探索实践。
                </p>
                <p>
                    目前，全球已有 300
                    多个孩子通过综合能力的测试进入实验室，同各国职业艺术家展开学术
                    艺术讨论， 并受益于实验室体系脚本。 通过 Composition 认知、
                    Switch 交换机、 Brainstorm
                    脑力风暴三个方向艺术脚本的专业训练与创作实践，搭建起了优秀艺术思维与通向全球艺术
                    舞台的正确能力阶梯。
                </p>
                <p>
                    <span>至</span>
                    2021 年，
                    几乎每年送选国际高级别学术类艺术大赛的实验室会员作品，
                    均包揽金银奖项。
                    大量会员凭自身真实能力考入全球顶级艺术院校，或通过艺术作品成功申请国外私立高中及
                    常春藤盟校并获全额奖学金。
                </p>
            </div>

            <div className="lab-leaders">
                <h3>管理与学术艺术研发团队</h3>
                <div className="leader-list">
                    {LEADER_LIST.map(renderLeader)}
                </div>

                <div className="teacher-list">
                    {TEACHER_LIST.map(renderTeacher)}
                </div>
            </div>

            <div className="author-studio">
                <h3>UAD认证画室</h3>
                <p>
                    美国波士顿画室 / 北京清华大学画室 / 北京翡翠山画室 /
                    北京燕西画室 / 北京西山湖画 室 /
                    北京朝阳路画室／北京北印画室 / 广州白云区第一画室
                </p>
            </div>
        </Layout>
    );
};

export default Lab;
