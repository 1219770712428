import React, { MouseEvent } from "react";
import Animate, { ANIMATION_TYPE } from "./Animate";
import BaseComponent from "./BaseComponent";
import Button from "./Button";
import "./Model.scss";
import Portal from "./Portal";

interface IModalProps {
    //默认：'center','bottom','_top'
    pos?: string;
    //是否有蒙版
    mask?: boolean;
    //弹出框动画效果
    animation?: string;
    onClose?: () => void;
    className?: string;
}

interface IState {
    scroll: boolean;
    props: { [key: string]: any };
}

/***
 * 弹出框
 */
export default class Model extends BaseComponent<IModalProps, IState> {
    static defaultProps = {
        pos: "center",
        mask: true,
        animation: { duration: 500, animation: ANIMATION_TYPE.EXPAND },
        isModel: false,
    };

    state = {
        scroll: false,
        props: {},
    };

    stopClick = (e: MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
    };

    handleMaskClick = (): void => {
        this.props.onClose && this.props.onClose();
    };

    render() {
        const { mask, animation, pos, className, children } = this.props;

        return (
            <Portal>
                <Button
                    className={`react-model ${className} model-${pos} ${
                        mask ? "mask" : ""
                    }`}
                    onClick={this.handleMaskClick}
                    stopPropagation={false}
                >
                    <div className="model-content" onClick={this.stopClick}>
                        <Animate runOnMount {...animation}>
                            {children}
                        </Animate>
                    </div>
                </Button>
            </Portal>
        );
    }
}
