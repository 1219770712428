import React from "react";
import BaseComponent from "../../../ui/BaseComponent";
import Button from "../../../ui/Button";
import Select from "../../../ui/Select";
import InfoCard from "./InfoCard";
import { ICourseInfo } from "./interface";

import "./CourseList.scss";

interface IProps {
    data: Array<ICourseInfo>;
    onClick: (course: ICourseInfo, index: number) => void;
}

export default class CourseList extends BaseComponent<IProps, any> {
    state = {
        select: 0,
        selectData: [],
    };

    static defaultProps = {
        data: [],
    };

    componentDidMount() {
        const { data } = this.props;
        this.getSelectData(data);
    }

    componentWillReceiveProps(np: IProps) {
        if (np.data.length !== this.props.data.length) {
            this.getSelectData(np.data);
        }
    }

    getSelectData = (data: ICourseInfo[]) => {
        const selectData = data.map((item: ICourseInfo) => {
            return {
                value: item.studioAddress,
            };
        });
        this.setState({
            selectData,
        });
    };

    handleConfirm = () => {
        const { data, onClick } = this.props;
        onClick(data[this.state.select], this.state.select);
    };

    onSelect = (value: string, index: number) => {
        this.setState({ select: index });
    };

    render() {
        const { data = [] } = this.props;
        const { selectData } = this.state;

        return (
            <div className="ui-course-model">
                <div className="ui-model-title">请选择实验</div>

                <Select data={selectData} onChange={this.onSelect} />

                <InfoCard
                    data={(data[this.state.select] || {}) as any}
                    title=""
                />

                <Button className="btn-confirm" onClick={this.handleConfirm}>
                    确认选择
                </Button>
            </div>
        );
    }
}
