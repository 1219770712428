import Http from "./Http";
import { METHOD, DATA_TYPE, CACHE_ENUM } from "./HttpEmun";
import Cookie from "../utils/Cookie";

export enum HTTP_STATE {
    //加载中
    LOADING = 1,
    //加载成功
    SUCCESS = 2,
    //加载失败
    ERROR = 3,
}

interface IPluginHttpProps {
    url: string;
    headers: { [key: string]: any } | string;
    post?: string;
    timeout?: number;
    dataType?: string;
}

export default class PluginHttp {
    static STATUS = {
        //加载中
        LOADING: 1,
        //加载成功
        SUCCESS: 2,
        //加载失败
        ERROR: 3,
    };

    /**
     * ajax请求插件
     * @action in {url:*地址,headers:参数}
     * @action out {loading:*地址,data:参数}
     * @param {*} next
     * @param {*} action
     * @param {*} store
     */
    static ajax(options: IPluginHttpProps) {
        let { headers, url } = options;

        const COMMON_HEADERS = {
            userId: Cookie.get("userInfo"),
        };

        headers = {
            ...COMMON_HEADERS,
            ...(headers as object),
        };

        if (options.dataType !== "jsonp") {
            headers = JSON.stringify(headers);
        }

        const { dataType, timeout } = options;
        return Http.ajax({
            url,
            method: options.post || METHOD.POST,
            dataType: (dataType || DATA_TYPE.JSON) as DATA_TYPE,
            cache: CACHE_ENUM.NO_CACHE,
            timeout: timeout || 15000,
            body: headers,
        });
    }
}
