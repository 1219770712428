interface ICookieOptions {
    //该cookie的可访问路径
    path?: string;
    //该cookie的所属域名（多个域下的情况,如css.jj.cn和img.jj.cn想要访问各自设置的cookie，则domain应该是.jj.cn才可以，注意域名前面的点不是故意添加的，是必需）
    domain?: string;
    //该cookie的有效时间，当是数字类型时，是以天为单位。当是日期对像时，直接就是该日期
    expires?: number | Date;
    //设置secure为真，则该cookie只有在https协议下才会传输到服务端
    secure?: boolean;
}

class Cookie {
    /**
     * cookie操作主函数
     * @param {String} key 需要存储cookie的键（必需）
     *
     */
    public get = (key: string) => {
        const oldCookie: string[] = window.document.cookie.split(";");
        for (let i: number = 0; i < oldCookie.length; i++) {
            let currentCookie = oldCookie[i].trim();
            if (currentCookie.substring(0, key.length + 1) === key + "=") {
                return unescape(
                    decodeURIComponent(currentCookie.substring(key.length + 1))
                );
            }
        }
        return null; //没有设置该cookie的时候，返回null
    };

    /**
     * cookie操作主函数
     * @param {String} key 需要存储cookie的键（必需）
     * @param {String} value 需要存储cookie的值
     * @param {Object} options 设置域名，过期时间等参数，详细使用方法如下
     *
     */
    public set = (key: string, value: string, options?: ICookieOptions) => {
        let { expires, path, domain, secure } = options || {},
            arrCookie = [],
            date: Date;

        if (value === null) {
            value = "";
            expires = -1;
        }

        arrCookie.push(key + "=" + encodeURIComponent(value));
        if (expires && (typeof expires == "number" || expires.toUTCString)) {
            if (typeof expires == "number") {
                date = new Date();
                date.setTime(date.getTime() + expires * 24 * 60 * 60 * 1000);
            } else {
                date = expires;
            }
            arrCookie.push("expires=" + date.toUTCString()); // use expires attribute, max-age is not supported by IE
        }
        path && arrCookie.push("path=" + path);
        domain && arrCookie.push("domain=" + domain);
        secure && arrCookie.push("secure");
        window.document.cookie = arrCookie.join("; "); //设置当前cookie
    };
}

export default new Cookie();
