import React from "react";
import BaseComponent from "../../ui/BaseComponent";
import Menu from "./Menu";
import Header from "./Header";
import Footer from "./Footer";

import "./Layout.scss";

interface IProps {
    className?: string;
}

export default class Layout extends BaseComponent<IProps, any> {
    render() {
        const { children, className } = this.props;
        const docHeight = document.documentElement.clientHeight;
        return (
            <div
                className={`ui-layout ${className}`}
                style={{ minHeight: docHeight }}
            >
                <Header>
                    <Menu type="other" />
                </Header>

                <section className="ui-content">{children}</section>

                <Footer />
            </div>
        );
    }
}
