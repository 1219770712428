import React, { FunctionComponent } from "react";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";

import "./index.scss";

import HEAD_IMG from "./img/head_img.png";
import CONSULT_IMG from "./img/consult.png";
import MINIPROGRA_IMG from "./img/miniprogram.png";

const Home: FunctionComponent<{}> = () => {
    const docHeight = window.innerHeight || window.screen.availHeight;

    return (
        <div className="ui-page home" style={{ minHeight: docHeight }}>
            <div className="ui-page-body">
                <div className="head-img">
                    <img src={HEAD_IMG} alt="" />
                </div>

                <div className="slogan">
                    领略全球职业艺术家的学术艺术脚本...
                </div>

                <div className="btn-list">
                    <Link className="art-lab" to="/official/lab" />
                    <Link className="art-script" to="/official/research" />
                    <Link className="art-gallery" to="/official/gallery" />
                </div>

                <div className="qrcode-list">
                    <div className="qrcode-item">
                        <div className="qrcode-item-title">咨询扫码</div>
                        <img src={CONSULT_IMG} alt="" />
                    </div>
                    <div className="qrcode-item">
                        <div className="qrcode-item-title">会员小程序登录</div>
                        <img src={MINIPROGRA_IMG} alt="" />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Home;
