import Config from "../../utils/Config";

const { serverUrl } = Config;

const Url = {
    // 视频列表
    VIDEO_INFO: serverUrl + "/getVideoInfo",
    // 参与人列表
    STAFF_LIST: serverUrl + "/getStaffList",

    SERVER_TIME: serverUrl + "/getCurrTime",

    COURSE_INFO: serverUrl + "/getClassDetail",

    COURSE_LIST: serverUrl + "/getClassList",
};

export default Url;
