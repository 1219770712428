import ReactDOM from "react-dom";
import BaseComponent from "./BaseComponent";

export default class Portal extends BaseComponent<{}, {}> {
    private el: HTMLElement = document.createElement("div");

    componentDidMount(): void {
        document.body.appendChild(this.el);
    }

    componentWillUnmount(): void {
        document.body.removeChild(this.el);
    }

    render() {
        const { children } = this.props;
        // console.log('-----------msg---------', msg);
        return ReactDOM.createPortal(children, this.el);
    }
}
