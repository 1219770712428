import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";
import Flex from "../../../ui/Flex";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";

const Lab: FunctionComponent<{}> = () => {
    return (
        <Layout background={BACKGROUND_IMG} className="research">
            <div className="research-head">
                <h2>会员艺术脚本</h2>
                <Flex className="steps" ratio={[0, 1, 0, 0, 0, 0, 0, 1, 0]}>
                    <div className="arrow" />
                    <div className="step-line line1" />
                    <div className="step step1" />
                    <div className="step-line  line2" />
                    <div className="step step2" />
                    <div className="step-line  line3" />
                    <div className="step step3" />
                    <div className="step-line  line4" />
                    <div className="arrow" />
                </Flex>
                <div className="head-title-arrow" />
            </div>

            <div className="research-content">
                <Flex className="concept">
                    <div className="concept-item composition">
                        <div className="concept-item-head">Composition认知</div>
                        <div className="concept-item-body">
                            与艺术创作有关的重要认知实验。 例如：
                            正确理解我们真实的世界、观察复制能力训练、控
                            笔能力训练、造型构建认知、创建色彩与规律认知、立体认知、头脑分类记忆素材库、创作
                            思维方法，
                            以及了解更多意思的规律。这将非常重要，是建立优秀艺术思维的基础。通过训练与创作将帮助了解和掌握！
                        </div>
                        <div className="concept-item-foot" />
                    </div>

                    <div className="concept-item switch">
                        <div className="concept-item-head">Switch交换机</div>
                        <div className="concept-item-body">
                            尝试探索事物的解构拆分与创新重组， 建立交换思维。
                            例如： 设计时空交换、 角色交换、 色
                            彩交换、情绪交换、多维度交换等。通过方法论学习与实践，结合大量训练与创作进行思维
                            拉伸， 充分开发和调用创作灵力！
                        </div>
                        <div className="concept-item-foot" />
                    </div>

                    <div className="concept-item brainstorm">
                        <div className="concept-item-head">
                            Brainstorm头脑风暴
                        </div>
                        <div className="concept-item-body">
                            不同的创作灵感、思维、表现技法构成了丰富多彩的绘画世界。来自全球不同地区的艺术家
                            将分享他们有趣的艺术创作过程，提供最具前沿的艺术风格、技法、表现手段、综合创作方
                            法。 通过实验提升整体艺术创作能力。
                        </div>
                        <div className="concept-item-foot" />
                    </div>
                </Flex>

                <div className="research-tips">
                    <h4>以上艺术脚本包含纸质稿件或视频形式</h4>
                    <p>
                        本实验室开放年龄为<span>7-17岁</span>
                        （包含零基础绘画爱好者）
                    </p>
                </div>
            </div>
        </Layout>
    );
};

export default Lab;
