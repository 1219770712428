import React from "react";
import { match } from "react-router-dom";
import { History } from "history";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import BaseComponent from "../../../ui/BaseComponent";
import Flex, { FlexConst } from "../../../ui/Flex";
import Loading from "../../../ui/Loading";
import PluginHttp, { HTTP_STATE } from "../../../http/PluginHttp";
import AssessList from "../ui/AssessList";
import Button from "../../../ui/Button";
import AlVideoPlayer from "../../common/AlVideoPlayer";
import createAliOssUpload from "../ui/AlOssUpload";
import Rem from "../../../utils/Rem";
import Url from "../Const";

import "./index.scss";
import Model from "../../../ui/Model";
import Popup from "../../../ui/Popup";

interface IProps {
    match: match & {
        params: {
            key: string;
        };
    };
    history: History;
}

interface IState {
    loading: HTTP_STATE;
    workList: Array<any>;
    pathname: string;
    currentWork: number;
    myAssess: any;
    message: string;
    isUpload: boolean;
    showTextarea: boolean;
    editorState: EditorState | undefined;
    showAssess: boolean;
}

const buttons = [
    {
        text: "添加文字评语 +TXT",
        // accept: "txt",
    },
    {
        text: "添加图片评语 +PIC",
        accept: "image/*",
    },
    {
        text: "添加音频评语 +AUDIO",
        accept: "audio/*",
    },
    {
        text: "添加视频评语 +VIDEO",
        accept: "video/*",
    },
    {
        text: "查看我的评语 View my comments",
    },
];

export default class Assess extends BaseComponent<IProps, IState> {
    state = {
        loading: 1,
        // 视频信息
        workList: [],
        // 我的评语
        myAssess: {},
        pathname: "",
        // 当前作品
        currentWork: 0,
        message: "",
        isUpload: false,
        showTextarea: false,
        showAssess: false,
        editorState: undefined
    };

    private uploader: any = null;
    private textarea: HTMLTextAreaElement | null = null;

    componentDidMount() {
        this.loadData();

        this.uploader = createAliOssUpload({
            timeout: 60000,
            onUploadProgress: this.onUploadProgress,
            onUploadSucceed: this.onUploadSucceed,
            onUploadFailed: this.onUploadFailed,
        });
    }

    loadData = () => {
        const { match } = this.props;
        const key = match.params.key;

        PluginHttp.ajax({
            url: Url.WORK_LIST,
            headers: {
                key,
            },
        })
            .then((res) => {
                if (res.returnCode === "10000") {
                    this.setState(
                        {
                            loading: 2,
                            workList: res.workList,
                        },
                        () => {
                            this.loadMyAssess();
                        }
                    );
                } else {
                    this.setState({
                        loading: 3,
                        message: res.returnDesc,
                    });
                }
            })
            .catch((e: Error) => {
                this.setState({
                    loading: 3,
                });
            });
    };

    onUploadProgress = (
        uploadInfo: any,
        totalSize: number,
        progress: number
    ) => {};

    onUploadSucceed = (uploadInfo: any) => {
        const { videoType, vid } = uploadInfo;
        const typeMap: any = {
            img: 2,
            audio: 3,
            video: 4,
        };

        console.log("--------uploadInfo------", uploadInfo);
        this.submitAssess(typeMap[videoType], {
            videoId: vid,
        });
    };

    onUploadFailed = (uploadInfo: any) => {
        this.setState({
            message: "上传，请稍后重试",
            isUpload: false,
        });
    };

    /**
     * 我的评语
     * @param workId
     */
    loadMyAssess = () => {
        const { match } = this.props;
        const key = match.params.key;

        const { currentWork, workList } = this.state;
        const work = workList[currentWork];
        const { workId } = work;

        PluginHttp.ajax({
            url: Url.MY_ASSESS,
            headers: {
                workId,
                key,
            },
        })
            .then((res) => {
                if (res.returnCode === "10000") {
                    const { inviteType } = res;
                    let videoType;
                    if (inviteType === "2") {
                        videoType = "img";
                    } else if (videoType === "3") {
                        videoType = "audio";
                    } else if (videoType === "4") {
                        videoType = "video";
                    }
                    res.videoType = videoType;
                    this.setState({
                        myAssess: res,
                    });
                } else {
                    this.setState({
                        message: "获取我的评语失败",
                        myAssess: {},
                    });
                }
            })
            .catch((e: Error) => {
                this.setState({
                    message: "获取我的评语失败",
                    myAssess: {},
                });
            });
    };

    // 切换当前作品
    changeWork = (currentWork: number) => {
        this.setState(
            {
                currentWork,
            },
            () => {
                this.loadMyAssess();
            }
        );
    };

    // 下一站作品
    next = () => {
        this.changeWork(this.state.currentWork + 1);
    };

    // 上一张作品
    prev = () => {
        this.changeWork(this.state.currentWork - 1);
    };

    /**
     * 提交评语
     * @param inviteType 1 文字 2 图片 3 音频 4 视频
     */
    submitAssess = (inviteType: 1 | 2 | 3 | 4, args: object = {}) => {
        const { match } = this.props;
        const key = match.params.key;

        const { currentWork, workList } = this.state;
        const work = workList[currentWork];
        const { workId } = work;

        PluginHttp.ajax({
            url: Url.SUBMIT_ASSESS,
            headers: {
                workId,
                key,
                inviteType,
                ...args,
            },
        })
            .then((res) => {
                if (res.returnCode === "10000") {
                    this.setState({
                        isUpload: false,
                        showTextarea: false,
                        message: "评语提交成功",
                    });
                } else {
                    this.setState({
                        isUpload: false,
                        showTextarea: false,
                        message: res.returnDesc || "评语提交失败",
                    });
                }
            })
            .catch((e: Error) => {
                this.setState({
                    isUpload: false,
                    showTextarea: false,
                    message: "评语提交失败",
                });
            });
    };

    /**
     * 上传文件
     * @param e
     */
    handleFileChange = (
        index: number,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        console.log(e.target.files);
        let { target } = e;
        if (!target) {
            return;
        }
        const files = target.files || [];
        for (let i = 0; i < files.length; i++) {
            console.log(files[i]);
            // (files[i] as any).inviteType = index;
            this.uploader.addFile(files[i]);
        }

        this.setState({
            isUpload: true,
        });
        this.uploader.startUpload();
    };

    handleBtnClick = (index: number) => {
        if (index === 0) {
            this.setState({
                showTextarea: true,
            });
        } else {
            this.setState({
                showAssess: true,
            });
        }
    };

    /**
     * 提交文字评语
     */
    submitTxtAssess = (index: number) => {
        if (index === 0) {
            this.setState({
                showTextarea: false,
            });
            return;
        }
        if (!!this.state.editorState) {
            const editorState: EditorState = this.state.editorState!;
            
            this.setState({
                isUpload: true,
            });
            this.submitAssess(1, {
                inviteDetail: draftToHtml(
                    convertToRaw(editorState!.getCurrentContent())
                ),
            });
        }
    };

    onEditorStateChange = (editorState: any) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const {
            workList,
            loading,
            myAssess,
            currentWork,
            message,
            isUpload,
            showAssess,
            showTextarea,
            editorState,
        } = this.state;

        const workInfo: any = workList[currentWork] || {};
        const pre = currentWork > 0;
        const next = currentWork < workList.length - 1;

        return (
            <Rem>
                <div className="ui-page">
                    <div className="page-head">
                        <span className="title">UAD</span>
                        <span className="sys">评语端 Comment</span>
                    </div>
                    <div className="page-body">
                        <Loading state={loading}>
                            <Flex
                                className="work-list"
                                dir={FlexConst.dir.top}
                                ratio={[0, 0]}
                            >
                                <div className="work-content">
                                    <AlVideoPlayer
                                        key={workInfo.videoId}
                                        data={workInfo}
                                    />

                                    <div className="work-time">
                                        {workInfo.workTime}
                                    </div>
                                </div>

                                {workList.length ? (
                                    <Flex
                                        className="work-tools"
                                        ratio={[0, 1, 0]}
                                    >
                                        <div
                                            className={`work-tool-btn ${
                                                pre ? "pre" : ""
                                            }`}
                                            onClick={
                                                pre ? this.prev : undefined
                                            }
                                        >
                                            {pre ? "上一张" : ""}
                                        </div>
                                        <div className="work-tool-text">
                                            作品{currentWork + 1}/
                                            {workList.length}
                                        </div>
                                        <div
                                            className={`work-tool-btn ${
                                                next ? "next" : ""
                                            }`}
                                            onClick={
                                                next ? this.next : undefined
                                            }
                                        >
                                            {next ? "下一张" : ""}
                                        </div>
                                    </Flex>
                                ) : null}
                            </Flex>

                            {/* <div className="course-info">
                                <h3>实验任务说明 Brief</h3>
                                <div className="course-desc">
                                    的方法是打发第三方第三方达舒服大师傅的说法大师傅大厦发多少发大水发大水发达双方都舒服大师傅的说法的的方法是打发第三方第三方达舒服大师傅的说法大师傅大厦发多少发大水发大水发达双方都舒服大师傅的说法的
                                </div>
                            </div> */}

                            <div className="btn-list">
                                {buttons.map((item, index) => {
                                    if (item.accept) {
                                        return (
                                            <div
                                                className="btn-item"
                                                key={index}
                                            >
                                                {item.text}
                                                <input
                                                    type="file"
                                                    accept={item.accept}
                                                    onChange={this.handleFileChange.bind(
                                                        this,
                                                        index
                                                    )}
                                                />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <Button
                                                className="btn-item"
                                                key={index}
                                                onClick={this.handleBtnClick.bind(
                                                    this,
                                                    index
                                                )}
                                            >
                                                {item.text}
                                            </Button>
                                        );
                                    }
                                })}
                            </div>

                            {showAssess ? (
                                <AssessList data={myAssess || {}} />
                            ) : null}
                        </Loading>

                        {showTextarea ? (
                            <Model>
                                <Popup
                                    className="txt-popup"
                                    title="添加文字评语"
                                    buttons={["取消", "提交"]}
                                    onButtonListener={this.submitTxtAssess}
                                >
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="home-wrapper"
                                        editorClassName="rich-editor"
                                        onEditorStateChange={
                                            this.onEditorStateChange
                                        }
                                        editorStyle={{border: "1px solid #d6d6d6", height: '200px', padding: '5px 10px'}}
                                        // toolbarHidden
                                        spellCheck
                                    />
                                </Popup>
                            </Model>
                        ) : null}

                        {/* 接口异常提醒 */}
                        {message ? (
                            <Model>
                                <Popup
                                    buttons={["知道了"]}
                                    onButtonListener={() => {
                                        this.setState({
                                            message: "",
                                        });
                                    }}
                                >
                                    {message}
                                </Popup>
                            </Model>
                        ) : null}

                        {/* 评语提交中弹框 */}
                        {isUpload ? (
                            <Model>
                                <Popup
                                    buttons={[]}
                                    onButtonListener={() => {
                                        this.setState({
                                            isUpload: false,
                                        });
                                    }}
                                >
                                    <Loading
                                        className="uploading"
                                        state={4}
                                        text="评语提交中"
                                    />
                                </Popup>
                            </Model>
                        ) : null}
                    </div>

                    <div className="page-foot">UAD 艺术实验室 版本1.0</div>
                </div>
            </Rem>
        );
    }
}
