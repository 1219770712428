import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "./pages/NotFound/NotFound";
import Login from "./pages/Login/Login";
import Course from "./pages/Course/Course";
import Assess from "./pages/Assess";
import PCAssess from "./pages/Assess/pc";
import MobileAssess from "./pages/Assess/mobile";
import CourseList from "./pages/Course/CourseList";
import BindSuccess from './pages/BindSuccess'

import "./Page.scss";

export default () => (
    <Switch>
        <Route
            exact
            path="/wechatweb"
            render={() => <Redirect to="/wechatweb/index" push />}
        />
        <Route path="/wechatweb/index" component={Login} />
        <Route path="/wechatweb/course/:id" component={Course} />
        <Route path="/wechatweb/list/:source" component={CourseList} />
        <Route path="/wechatweb/assess/:key" component={Assess} exact />
        <Route path="/wechatweb/assess/pc/:key" component={PCAssess} exact />
        <Route
            path="/wechatweb/assess/mobile/:key"
            component={MobileAssess}
            exact
        />
        <Route
            path="/wechatweb/bindSucc"
            component={BindSuccess}
            exact
        />
        <Route path="/wechatweb/404" component={NotFound} />
        <Route component={NotFound} />
    </Switch>
);
