import React, { FunctionComponent, PropsWithChildren } from "react";
import Header from "./Header";
import Footer from "./Footer";

import "./Layout.scss";

interface IProps {
    className?: string;
    background: string;
    title?: string;
    contentStyle?: { [key: string]: any };
}

const Layout: FunctionComponent<PropsWithChildren<IProps>> = ({
    children,
    className,
    background,
    title,
    contentStyle,
}: PropsWithChildren<IProps>) => {
    const docHeight = document.documentElement.clientHeight;
    return (
        <div
            className={`ui-official-layout ${className}`}
            style={{ minHeight: docHeight }}
        >
            <Header background={background} title={title} />

            <section className="ui-official-content" style={contentStyle}>
                {children}
            </section>

            <Footer />
        </div>
    );
};

export default Layout;
