enum PlatformEnum {
    Android = "android",
    IPhone = "iphone",
    IPad = "ipad",
    IOS = "ios",
}

enum DevTypeEnum {
    ANDROID = 1,
    IOS = 2,
    PC = 8,
}

class Device {
    private ua: string;

    constructor() {
        this.ua = window.navigator.userAgent.toLowerCase();
    }

    // 获取
    getSystem = (): PlatformEnum | void => {
        if (/android/.test(this.ua)) {
            return PlatformEnum.Android;
        } else if (/ipad/.test(this.ua)) {
            return PlatformEnum.IPad;
        } else if (/iphone os|iph os/.test(this.ua)) {
            return PlatformEnum.IPhone;
        }
    };

    /**
     * 设备类型
     *
     * @memberof Device
     */
    getPlatform = (): PlatformEnum | void => {
        if (/android/.test(this.ua)) {
            return PlatformEnum.Android;
        } else if (/ipad|iphone os|iph os/.test(this.ua)) {
            return PlatformEnum.IOS;
        }
    };

    /**
     * 设备类型（接口使用）
     *
     * @memberof Device
     */
    getDevType = (): DevTypeEnum | void => {
        if (/android/.test(this.ua)) {
            return 1;
        } else if (/ipad|iphone os|iph os/.test(this.ua)) {
            return 2;
        }
    };

    isBadAndroid = (): boolean => {
        return /android 5/.test(this.ua) || /android 4/.test(this.ua);
    };

    isWechat = (): boolean => {
        return /(MicroMessenger)/i.test(this.ua);
    };

    isIos = (): boolean => {
        return this.getPlatform() === PlatformEnum.IOS;
    };

    isAndroid = (): boolean => {
        return this.getPlatform() === PlatformEnum.Android;
    };
}

export default new Device();
