import React from "react";
import BaseComponent from "../../ui/BaseComponent";
import Button from "../../ui/Button";
import Model from "../../ui/Model";
import Portal from "../../ui/Portal";
import { Link } from "react-router-dom";

import "./Menu.scss";

import IMNI_PROGRAM_IMG from "./img/qrcode.png";

interface IMenu {
    name: string;
    route?: string;
    type: "page" | "model";
}

const INDEX_MENUS: Array<IMenu> = [
    {
        name: "脚本演示",
        route: "/wechatweb/list/demo",
        type: "page",
    },
    {
        name: "联系我们",
        route: "/",
        type: "page",
    },
];

const COMMON_MENUS: Array<IMenu> = [
    {
        name: "微信小程序",
        route: "/",
        type: "model",
    },
    {
        name: "隐私政策",
        route: "/",
        type: "page",
    },
    {
        name: "版权声明",
        route: "/",
        type: "page",
    },
    {
        name: "关于实验室",
        route: "/",
        type: "page",
    },
];

const OTHER_MENUS: Array<IMenu> = [
    {
        name: "首页",
        route: "/index",
        type: "page",
    },
];

interface IMenuProps {
    className?: string;
    type: "index" | "other";
}

export default class Menu extends BaseComponent<IMenuProps> {
    state = {
        show: false,
    };

    toggleModel = (show: boolean) => {
        this.setState({
            show: show,
        });
    };

    renderItem = (item: IMenu, index: number) => {
        if (item.type === "page") {
            return (
                <Link className="menu-item" key={index} to={item.route!}>
                    {item.name}
                </Link>
            );
        } else {
            return (
                <Button
                    key={index}
                    className="menu-item"
                    onClick={this.toggleModel.bind(this, true)}
                >
                    {item.name}
                </Button>
            );
        }
    };

    render() {
        const { className, type } = this.props;
        const menus = (type === "index" ? INDEX_MENUS : OTHER_MENUS).concat(
            COMMON_MENUS
        );
        const { show } = this.state;

        return (
            <div className={`ui-menu ${className}`}>
                {menus.map(this.renderItem)}

                {show ? (
                    <Portal>
                        <Model
                            className="menu-model-wx"
                            pos="center"
                            onClose={this.toggleModel.bind(this, false)}
                        >
                            <img src={IMNI_PROGRAM_IMG} alt="" />
                        </Model>
                    </Portal>
                ) : null}
            </div>
        );
    }
}
