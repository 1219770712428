import React, { SFC } from "react";
import Card from "../../common/Card";
import Scroll from "../../../ui/Scroll";
import { IStaffData } from "../ui/interface";

import "./StudentCard.scss";

interface IProps {
    data: {
        teacher: IStaffData | null;
        students: Array<IStaffData>;
    };
}

const StudentCard: SFC<IProps> = ({ data }: IProps) => {
    const { teacher, students } = data || {};

    return (
        <Card title="参与者" className="student">
            <ul>
                <li>
                    <span className="name">实验指导</span>
                    <span className="value">
                        {teacher && teacher!.staffName}
                    </span>
                </li>
                <li>
                    <span className="name">实验人</span>
                </li>
                <Scroll>
                    <div className="students-list">
                        {students.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${item.sign ? "sign" : ""} ${
                                        item.work ? "work" : ""
                                    }`}
                                >
                                    {item.staffName}
                                </div>
                            );
                        })}
                    </div>
                </Scroll>
            </ul>
        </Card>
    );
};

export default StudentCard;
