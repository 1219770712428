import React from "react";
import "./Footer.scss";

const Footer: React.SFC<{}> = () => {
    return (
        <footer className="ui-official-footer">
            <div className="line" />
            <p className="txt">
                本网站是UAD艺术实验室唯一官方网站，所有内容具有独立知识产权及著作权，受中国大陆地区及联合艺术家所在地区法律保护。
            </p>
            <p className="copy-right">
                Copyright ©2019-2020 FINCOM Corporation, All Rights
                Reserved。ICP备案号：
                <a
                    target="_blank"
                    href="https://beian.miit.gov.cn/"
                    rel="noopener noreferrer"
                >
                    京ICP备20007484号
                </a>
            </p>
            {/* <div className="beian"></div> */}
        </footer>
    );
};
export default Footer;
