import React from "react";
import BaseComponent from "./BaseComponent";

import "./Button.scss";

export interface IButtonProps {
    //按钮状态
    disable?: boolean;
    //样式
    className?: string;
    //点击事件
    onClick: (...args: any) => void;
    /* 以下为其它参数传过来的参数（没有实际用途） */
    style?: { [key: string]: any };
    ratio?: number[];
    comp?: React.ElementType;
    cross?: string;
    column?: string;
    box?: string;
    ["data-flex-box"]?: string;
    stopPropagation?: boolean;
}

export default class Button extends BaseComponent<IButtonProps, any> {
    private btn: HTMLDivElement | null = null;
    private clickIng = false;
    private clickTimer: number | null = null;

    static defaultProps = {
        disable: false,
        stopPropagation: true,
    };

    state = {
        clicking: false,
    };

    componentDidMount() {
        this.btn!.addEventListener("click", this.handleClick, false);
    }

    componentWillUnmount() {
        this.btn!.removeEventListener("click", this.handleClick, false);
        this.clickTimer && clearTimeout(this.clickTimer);
    }

    handleClick = (e: Event): void => {
        console.log(e.currentTarget);

        const { onClick, disable, stopPropagation } = this.props;

        if (stopPropagation) {
            // 阻止合成事件的冒泡
            e.stopPropagation();
        }

        if (disable) {
            return;
        }

        // console.log("Button Component click time: ", Date.now());
        if (this.clickIng) {
            return;
        }

        this.clickIng = true;
        this.setState({ clicking: true }, () => {
            onClick && onClick(e);

            this.clickTimer && clearTimeout(this.clickTimer);
            this.clickTimer = window.setTimeout(() => {
                this.setState({ clicking: false });
                this.clickIng = false;
            }, 300);
        });
    };

    render() {
        const { className, style, children, disable } = this.props;
        return (
            <div
                style={style}
                ref={el => {
                    this.btn = el;
                }}
                className={`r-b-d ${className ? className : "r-b"} ${
                    disable ? "disable" : ""
                }`}
                data-flex-box={this.props["data-flex-box"]}
            >
                {typeof children === "string" ? (
                    <div>{children}</div>
                ) : (
                    children
                )}
            </div>
        );
    }
}
