import React from "react";
import Model from "../../ui/Model";
import Popup from "../../ui/Popup";

class NotFound extends React.Component<any, any> {
    state = {
        message: "",
    };

    componentWillMount() {
        const { match = {} } = this.props;
        const { params } = match || {};
        this.setState({
            message: decodeURIComponent(params.desc || "系统异常，请稍后再试"),
        });
    }

    enter = () => {
        this.setState({ animated: "hinge" });
    };

    render() {
        return (
            <div
                className="center"
                style={{
                    width: "100%",
                    height: "100%",
                }}
            >
                {this.state.message ? (
                    <Model>
                        <Popup
                            buttons={["返回首页"]}
                            content={this.state.message}
                            onButtonListener={() => {
                                this.props.history.push("/wechatweb/index");
                            }}
                        />
                    </Model>
                ) : null}
            </div>
        );
    }
}

export default NotFound;
