/*
 * @Author: wugz
 * @Date: 2018-10-25 17:35:01
 * @Last Modified by: zhangyg
 * @Last Modified time: 2018-10-29 17:35:27
 */

import * as React from "react";

/**
 *
 *
 * @class BaseComponent
 * @extends {React.Component<P, S>}
 * @template P
 * @template S
 */
class BaseComponent<P = {}, S = {}> extends React.Component<P, S> {
    

    constructor(props: Readonly<P>) {
        super(props);
        this.lifes = [];
    }

    public shouldComponentUpdate(nextProps:any = {}, nextState:any = {}) {
        const thisProps:any = this.props || {};
        const thisState:any = this.state || {};
        nextState = nextState || {};
        nextProps = nextProps || {};

        if (
            Object.keys(thisProps).length !== Object.keys(nextProps).length ||
            Object.keys(thisState).length !== Object.keys(nextState).length
        ) {
            return true;
        }

        for (const key in nextProps) {
            if (thisProps[key] !== nextProps[key]) {
                return true;
            }
        }

        for (const key in nextState) {
            if (thisState[key] !== nextState[key]) {
                return true;
            }
        }
        return false;
    }

    public componentDidCatch(err: any, info: any) {
        console.log("componentDidCatch-", err, info);
        return false;
    }

    public componentWillUnmount(): void {
        //执行方法
        this.lifes.map((call: () => void) => {
            call();
            return call;
        });
        this.lifes = [];
    }

    // 定义一个存放方法的数组
    private lifes: Array<() => void>;

    
    /**
     * 生命周期管理方法，在组件释放时调用
     * @param {*function} call 触发调用的方法
     */
    lifePush = (call: () => void) => {
        if (!this.lifes) {
            this.lifes = [];
        }
        this.lifes.push(call);
    };

    public render() {
        return <div />;
    }
}

export default BaseComponent;
