import React from "react";
import BaseComponent from "./BaseComponent";

import "./Flex.scss";

class FlexConst {
    //主轴对齐方式
    static main = {
        // right：从右到左
        right: "right",
        // left：从左到右（默认）
        left: "left",
        // justify：两端对齐
        justify: "justify",
        // center：居中对齐
        center: "center",
    };

    //交叉轴对齐方式
    static cross = {
        // top：从上到下（默认）
        top: "top",
        // bottom：从上到下
        bottom: "bottom",
        // baseline：基线对齐
        baseline: "baseline",
        // center：居中对齐
        center: "center",
        // stretch：高度并排铺满
        stretch: "stretch",
    };

    // mean：子元素平分空间
    static box = {
        // mean：子元素平分空间
        mean: "mean",
        // first：第一个子元素不要多余空间，其他子元素平分多余空间
        first: "first",
        // last：最后一个子元素不要多余空间，其他子元素平分多余空间
        last: "last",
        // justify：两端第一个元素不要多余空间，其他子元素平分多余空间
        justify: "justify",
    };

    //主轴方向
    static dir = {
        // top：从上到下
        top: "top",
        // right：从右到左
        right: "right",
        // bottom：从下到上
        bottom: "bottom",
        // left：从左到右（默认）
        left: "left",
    };
}

export { FlexConst };

interface IFlexProps {
    ratio?: number[];
    //组件
    comp?: React.ElementType;
    //主轴对齐方式
    main?: string;
    //交叉轴对齐方式
    cross?: string;
    //子元素设置
    box?: string;
    //主轴方向
    dir?: string;
    content?: "start" | "end" | "between" | "center";
    align?: "start" | "end" | "stretch" | "center" | "baseline";
    className?: string;
    column?: string;
    style?: { [key: string]: any };

    // comp 对应的属性
    [key: string]: any;
}

export default class Flex extends BaseComponent<IFlexProps, any> {
    static defaultProps = {
        //默认水平方向
        column: false,
        dir: FlexConst.dir.left,
        box: FlexConst.box.mean,
        cross: FlexConst.cross.center,
        // main:FlexConst.main.left,
    };

    getFlex(): string {
        const { dir, box, ratio, main, cross } = this.props;
        const arr = [];
        arr.push("dir:" + dir);
        !ratio && arr.push("box:" + box);
        main && arr.push("main:" + main);
        if (dir !== FlexConst.dir.top && cross) arr.push("cross:" + cross);
        return arr.join(" ");
    }

    renderChildren(): React.ReactNode {
        const { children, ratio } = this.props;
        // console.log("-dddd&&&&&&&&&&&&&&&dddd", ratio);
        if (!ratio) return children;
        // console.log("-dddd&&&&&&&&&&&&&&&dddd");
        return React.Children.map(children, (child: any, index) => {
            if (!child || !ratio) return;
            return React.cloneElement(child, { "data-flex-box": ratio[index] });
        });
    }

    render() {
        const {
            children,
            style,
            comp,
            className,
            cross,
            column,
            box,
            ratio,
            main,
            ...other
        } = this.props;
        return (
            <div
                data-flex={this.getFlex()}
                className={className}
                style={style}
                {...other}
            >
                {this.renderChildren()}
            </div>
        );
    }
}
