import React from "react";

export default class Rem extends React.Component<any, any> {
    componentWillMount() {
        this.init();
    }

    componentWillUnmount() {
        const evtFn = this.getEvn();
        window.removeEventListener(evtFn, this.setSize, !1);
    }

    init = (): void => {
        const evtFn = this.getEvn();
        this.setSize();
        window.addEventListener(evtFn, this.setSize, !1);
    };

    getEvn = () => {
        return "orientationchange" in window ? "orientationchange" : "resize";
    };

    setSize = () => {
        const winDoc = window.document,
            winDocDoc = winDoc.documentElement,
            psdW = 720 / 100;
        let pageW = winDocDoc.clientWidth || 320;
        pageW > 720 && (pageW = 720);
        winDocDoc.style.fontSize = pageW / psdW + "px";
        winDoc.body.style.fontSize = '.24rem';
    };

    render() {
        return this.props.children;
    }
}
