import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";

const Lab: FunctionComponent<{}> = () => {
    const [width, setWidth] = useState(900);

    function getWidth() {
        const temp = window.innerWidth || window.screen.availWidth;
        setWidth(temp < 1240 ? 1240 : temp);
    }

    useEffect(() => {
        getWidth();
        window.addEventListener("resize", getWidth);
    }, []);

    return (
        <Layout
            background={BACKGROUND_IMG}
            title="学术艺术画廊"
            className="gallery"
            contentStyle={{
                height: width * 0.83,
            }}
        >
            <div className="gallery-preface">
                <h3>The world is huge ／ Academic Art Exhibition</h3>
                <p>
                    几乎所有人都认为孩童时代有着最为丰富的想象力，羡慕天马行空无拘束。可是有多少人愿
                    意去认真了解在这背后孩子们的内心思考以及深层逻辑是什么？
                </p>
                <p>
                    假如我们希望时光倒流重返到七岁，去圆自己儿时的那个艺术梦想，那么现在的你，是否愿
                    意去探索认知孩子们的内心世界，
                    并象他们探索未知世界一样勇敢？
                </p>
                <p>
                    这里平行展出 AB 两部分内容： A 、 UAD 艺术实验室 7-17
                    岁会员的部分画室作品； B 、 实验
                    室职业艺术家的个人作品。当用严肃而认真的态度去观看每一幅作品时，会发现自己的认知
                    也许会被颠覆。 于此，
                    作为学术艺术研究的这次展览也因为你的加入而圆满！
                </p>
                <div className="weight">—— 世界很大，你在哪里？</div>
            </div>

            <div className="gallery-routes">
                <Link to="/official/gallery/member">会员作品</Link>
                <Link to="/official/gallery/artist">艺术家作品</Link>
            </div>

            <div className="gallery-imgs" />

            <div className="gallery-tip">
                <h4>画廊作品流动展出，定期更新</h4>
                <p>展出作品版权归作者所有，严禁盗用。</p>
            </div>
        </Layout>
    );
};

export default Lab;
