import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Http from "../../../http/Http";
import Flex, { FlexConst } from "../../../ui/Flex";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";

const MemberGallery: FunctionComponent<any> = ({ match }) => {
    const { params = {} } = match;
    const { type } = params;

    const [works, setWorks] = useState([]);

    function loadWorks() {
        const url = `https://wwwuad.oss-cn-beijing.aliyuncs.com/gallery/${type}.json`;
        Http.ajax({url, method: 'get'})
            .then((res) => {
                console.log('sdfads',url)
                setWorks(res);
            })
            .catch(() => {});

        // fetch(url, {method: 'get', mode: 'no-cors'})
        //     .then((res)=> {
        //         console.log('sdsdfasdaf', res);
        //     })
    }

    useEffect(() => {
        loadWorks();
    });

    function renderMemberWork(item: any, index: number) {
        return (
            <Flex className={`member-work-item`} key={index} ratio={[0, 0]}>
                <div className="work-item-left">
                    <img src={item.url} alt="" />
                </div>

                <div className="work-item-right">
                    <div className="work-base-info">
                        <span className="work-title">【{item.title}】</span>
                        <span className="work-author">作者：{item.author}</span>
                        <span className="author-age">年龄：{item.age}</span>
                    </div>
                    <div className="work-prize">{item.prize}</div>
                    <div className="work-desc">{item.description}</div>
                </div>
            </Flex>
        );
    }

    function renderArtistWork(item: any, index: number) {
        return (
            <Flex
                className={`art-work-item ${type}`}
                key={index}
                ratio={[0, 0]}
                cross={FlexConst.cross.top}
            >
                <div className="work-item-left">
                    <img src={item.url} alt="" />
                    {item.size || item.size ? (
                        <div>
                            <span className="work-title">【{item.title}】</span>
                            <span className="work-size">{item.size}</span>
                        </div>
                    ) : null}
                </div>

                <div className="work-item-right">
                    <div className="author-avatar">
                        <img src={item.avatar} alt="" />
                    </div>
                    <div className="author-base-info">
                        <span className="author-name">{item.author}</span>
                        <span className="author-country">{item.country}</span>
                    </div>
                    <div className="author-major">主要作品：{item.major}</div>
                    <div className="author-desc">{item.description}</div>
                </div>
            </Flex>
        );
    }

    function getHeadOptions() {
        return type === "member"
            ? {
                  title: "会员作品",
                  to: "/official/gallery/artist",
                  toTitle: "艺术家作品",
              }
            : {
                  title: "艺术家作品",
                  to: "/official/gallery/member",
                  toTitle: "会员作品",
              };
    }

    const headOptions = getHeadOptions();

    return (
        <Layout background={BACKGROUND_IMG} className="member-gallery">
            <div className="member-head-title">
                <div className="head-title-in">
                    <h3>{headOptions.title}</h3>

                    <Link to={headOptions.to}>{headOptions.toTitle}</Link>
                </div>

                <div className="head-title-arrow" />
            </div>

            <div className="work-list">
                {works.map(
                    type === "member" ? renderMemberWork : renderArtistWork
                )}
            </div>
        </Layout>
    );
};

export default MemberGallery;
