import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "./pages/NotFound/NotFound";
import Home from "./pages/official/Home";
import Lab from "./pages/official/Lab";
import Research from "./pages/official/Research";
import Gallery from "./pages/official/Gallery";
import MemberGallery from "./pages/official/MemberGallery";
// import MemberGallery from "./pages/official/MemberGallery";
import Studio from "./pages/official/Studio";
import Consult from "./pages/official/Consult";
import Declare from "./pages/official/Declare";

import "./Page.scss";

export default () => (
    <Switch>
        <Route
            exact
            path="/official"
            render={() => <Redirect to="/official/index" push />}
        />
        <Route path="/official/index" component={Home} />
        <Route path="/official/lab" component={Lab} />
        <Route path="/official/research" component={Research} />
        <Route path="/official/gallery" component={Gallery} exact />
        <Route path="/official/gallery/:type" component={MemberGallery} exact />
        <Route path="/official/studio" component={Studio} />
        <Route path="/official/consult" component={Consult} />
        <Route path="/official/declare" component={Declare} />
        <Route path="/official/404" component={NotFound} />
        <Route component={NotFound} />
    </Switch>
);
