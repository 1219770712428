import React from "react";
import { timeFormat } from "../utils";

import "./Timer.scss";

interface IProps {
    //倒计时时间
    time: number;
    //刷新时间
    refreshTime?: number;
    //
    onFinish?: () => void;
    // format
    format?: string;
}

export default class Timer extends React.Component<IProps, any> {
    static defaultProps = {
        time: 5555555,
        format: "yyyy-MM-dd hh:mm",
        refreshTime: 1000,
    };

    state = {
        time: this.props.time,
    };

    componentDidMount(): void {
        this.next();
    }

    UNSAFE_componentWillReceiveProps(nextProps: IProps): void {
        const { time } = nextProps;
        if (time !== this.props.time) {
            this.setState({
                time,
            });

            time > 0 && this.next();
        }
    }

    componentWillUnmount(): void {
        this.timeout && clearTimeout(this.timeout);
    }

    private timeout = 0;

    next = (): void => {
        const { refreshTime } = this.props;
        const { time } = this.state;
        this.setState({ time: time + refreshTime! });

        this.timeout && window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(this.next, refreshTime);
    };

    render(): React.ReactNode {
        const { time } = this.state;
        const { format } = this.props;
        const now = timeFormat(time, format!);

        // console.log("time-", now);
        return <p className="ui-time">{now}</p>;
    }
}
