const host = window.location.host;
const production = /study.uadvip.com/.test(host);

const Config = {
    id: "1",

    serverUrl: production ? origin + "/"
        : "http://39.98.70.64:8081/",
    // serverUrl: production ?  '': "/api",

    wxLoginCallbackServer: production ? 'https://admin.uadvip.com/'
        : "http://testapi.uadvip.com/",
};

export default Config;
