import React from "react";
import BaseComponent from "../ui/BaseComponent";

import "./Scroll.scss";

interface IProps {
    children: React.ReactNode;
}

export default class Scroll extends BaseComponent<IProps> {
    render() {
        const { children } = this.props;
        return (
            <div className="ui-scroll">
                <div className="ui-scroll-in">{children}</div>
            </div>
        );
    }
}
