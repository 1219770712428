import React from "react";
import { match } from "react-router-dom";
import { History } from "history";
import Device from "../../utils/Device";

interface IProps {
    history: History;
    match: match & {
        params: {
            key: string;
        };
    };
}

export default class Assess extends React.Component<IProps, any> {
    componentWillMount() {
        const { match, history } = this.props;
        const { params } = match;
        if (Device.getPlatform()) {
            history.push(`/wechatweb/assess/mobile/${params.key}`);
        } else {
            history.push(`/wechatweb/assess/pc/${params.key}`);
        }
    }

    render() {
        return null;
    }
}
