import React from "react";
import { match, Prompt } from "react-router-dom";
import { History } from "history";
import BaseComponent from "../../ui/BaseComponent";
import Layout from "../common/Layout";
import Flex, { FlexConst } from "../../ui/Flex";
import Model from "../../ui/Model";
import Loading from "../../ui/Loading";
import PluginHttp, { HTTP_STATE } from "../../http/PluginHttp";
import InfoCard from "./ui/InfoCard";
import StudentCard from "./ui/StudentCard";
import { ICourseInfo, IStaffData, ICourseSubData } from "./ui/interface";
import AlVideoPlayer from "../common/AlVideoPlayer";
import Popup from "../../ui/Popup";
import Url from "./Const";

import "./Course.scss";

interface IProps {
    match: match & {
        params: {
            id: string;
        };
    };
    history: History;
}

interface IState {
    loading: HTTP_STATE;
    subData: Array<ICourseSubData>;
    time: number;
    staff: {
        teacher: IStaffData | null;
        students: Array<IStaffData>;
    };
    course: ICourseInfo | object;
    needPrompt: boolean;
    showPrompt: boolean;
    pathname: string;
    currentSub: number;
    message: string;
}

export default class Course extends BaseComponent<IProps, IState> {
    state = {
        loading: 1,
        // 视频信息
        subData: [],
        // 服务器时间
        time: Date.now(),
        // 学生信息
        staff: {
            teacher: null,
            students: [],
        },
        // 课程信息
        course: {},
        // 离开的时候是否使用Prompt组件
        needPrompt: true,
        // 是否显示离开确认弹框
        showPrompt: false,
        pathname: "",
        // 当前小节索引
        currentSub: 0,

        // 通用提示弹框
        message: "",
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { match } = this.props;
        const classId = match.params.id;

        Promise.all([
            PluginHttp.ajax({
                url: Url.COURSE_INFO,
                headers: {
                    classId,
                },
            }),
            PluginHttp.ajax({
                url: Url.SERVER_TIME,
                headers: {},
            }),
            PluginHttp.ajax({
                url: Url.STAFF_LIST,
                headers: {
                    classId,
                },
            }),
        ])
            .then(this.handleFinish)
            .catch((e: Error) => {
                console.log(e);
                this.setState({
                    loading: 3,
                });
            });
    };

    handleFinish = ([courseData, timeData, staffData]: any) => {
        if (
            courseData.returnCode !== "10000" ||
            timeData.returnCode !== "10000" ||
            staffData.returnCode !== "10000"
        ) {
            if (
                courseData.returnCode === "40220" ||
                timeData.returnCode === "40220" ||
                staffData.returnCode === "40220"
            ) {
                this.setState({
                    message: "您还没有登录，快去登录吧",
                });
                return;
            }

            throw new Error(
                courseData.returnDesc ||
                    timeData.returnDesc ||
                    staffData.returnDesc
            );
        }

        let teacher: IStaffData | null = null,
            students: Array<IStaffData> = [];
        staffData.staffList.map((item: IStaffData) => {
            if (item.staffType === "teacher") {
                teacher = item;
            } else if (item.staffType === "student") {
                students.push(item);
            }
        });

        this.setState({
            loading: 2,
            course: courseData,
            time: timeData.currTime,
            subData: courseData.courseSubList,
            staff: {
                teacher,
                students,
            },
        });
    };

    handleBackConfirm = (location: any) => {
        this.setState({
            pathname: location.pathname,
            showPrompt: true,
            needPrompt: false,
        });
        return false;
    };

    handlePromptClick = (index: number) => {
        if (index === 0) {
            this.setState({
                showPrompt: false,
                needPrompt: true,
            });
        } else {
            const { history } = this.props;
            history.push(this.state.pathname);
        }
    };

    changeStep = (index: number) => {
        this.setState({
            currentSub: index,
        });
    };

    next = () => {
        this.changeStep(this.state.currentSub + 1);
    };

    prev = () => {
        this.changeStep(this.state.currentSub - 1);
    };

    render() {
        const {
            subData,
            course,
            staff,
            time,
            loading,
            needPrompt,
            showPrompt,
            currentSub,
        } = this.state;
        const videoInfo: any = subData[currentSub] || {};

        const pre = currentSub > 0;
        const next = currentSub < subData.length - 1;

        console.log("-----videoInfo------", videoInfo);

        return (
            <Layout className="page-course">
                <Loading state={loading}>
                    <Flex ratio={[0, 0]} cross={FlexConst.cross.top}>
                        <Flex
                            className="left"
                            dir={FlexConst.dir.top}
                            ratio={[0, 0]}
                        >
                            <div className="video-content">
                                <AlVideoPlayer
                                    key={videoInfo.videoId}
                                    data={videoInfo}
                                />
                            </div>

                            {subData.length ? (
                                <Flex className="video-tools" ratio={[0, 1, 0]}>
                                    <div
                                        className={`video-tool-btn ${
                                            pre ? "pre" : ""
                                        }`}
                                        onClick={pre ? this.prev : undefined}
                                    >
                                        {pre ? "上一节" : ""}
                                    </div>
                                    <div className="video-tool-text">
                                        步骤{currentSub + 1}/{subData.length}
                                    </div>
                                    <div
                                        className={`video-tool-btn ${
                                            next ? "next" : ""
                                        }`}
                                        onClick={next ? this.next : undefined}
                                    >
                                        {next ? "下一节" : ""}
                                    </div>
                                </Flex>
                            ) : null}
                        </Flex>

                        <div className="right">
                            <InfoCard
                                data={course as ICourseInfo}
                                title="当前时间"
                                time={new Date(time).valueOf()}
                            />
                            <StudentCard data={staff} />
                        </div>
                    </Flex>
                </Loading>

                {/* react-router Prompt离开确认 */}
                <Prompt message={this.handleBackConfirm} when={needPrompt} />

                {/* 离开确认弹框 */}
                {showPrompt ? (
                    <Model>
                        <Popup
                            buttons={["取消", "离开"]}
                            content="确认是否关闭课堂"
                            onButtonListener={this.handlePromptClick}
                        />
                    </Model>
                ) : null}

                {this.state.message ? (
                    <Model>
                        <Popup
                            buttons={["确认"]}
                            content={this.state.message}
                            onButtonListener={() => {
                                this.props.history.push("/wechatweb/index");
                            }}
                        />
                    </Model>
                ) : null}
            </Layout>
        );
    }
}
