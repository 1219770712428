import React from "react";
import BaseComponent from "../BaseComponent";
import Button from "../Button";

import "./index.scss";

export interface ISelectOption {
    value: string;
}

interface IProps {
    data: Array<any>;
    value?: string;
    onChange: (value: string, index: number) => void;
}

export default class Select extends BaseComponent<IProps, any> {
    static defaultProps = {
        data: [],
    };

    state = {
        value: "",
        show: false,
    };

    componentDidMount() {
        document.body.addEventListener("click", this.handleBodyClick, false);

        // const { value, data } = this.props;
        // this.setState({
        //     value: value || (data[0] && data[0].value) || "",
        // });
    }

    componentWillReceiveProps(np: IProps) {
        const { value, data } = np;
        if (np.data.length !== this.props.data.length) {
            this.setState({
                value: value || (data[0] && data[0].value) || "",
            });
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleBodyClick, false);
    }

    handleBodyClick = (e: Event) => {
        console.log("handleBodyClick");
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        this.setState({
            show: false,
        });
    };

    handleClick = (item: ISelectOption, index: number) => {
        this.setState(
            {
                value: item.value,
                show: false,
            },
            () => {
                this.props.onChange && this.props.onChange(item.value, index);
            }
        );
    };

    toggleDropdown = () => {
        console.log("toggleDropdown");
        // console.log("show: ", show);
        this.setState({
            show: !this.state.show,
        });
    };

    renderChild = (item: ISelectOption, index: number) => {
        return (
            <Button
                key={index}
                className="ui-select-dropdown-item"
                onClick={this.handleClick.bind(this, item, index)}
            >
                {item.value}
            </Button>
        );
    };

    render() {
        const { data } = this.props;
        const { value, show } = this.state;
        return (
            <div className="ui-select">
                <Button
                    className={`ui-select-value ${
                        show ? "arrow-up" : "arrow-down"
                    }`}
                    onClick={this.toggleDropdown}
                >
                    {value}
                </Button>

                {show ? (
                    <div className="ui-select-dropdown">
                        {data.map(this.renderChild)}
                    </div>
                ) : null}
            </div>
        );
    }
}
