import React from "react";
import "./Footer.scss";

const Footer: React.SFC<{}> = () => {
    return (
        <footer className="ui-footer">
            <div className="line" />
            <p className="txt">
                本网站是UAD艺术实验室
                &nbsp;唯一官方网站，所有内容具有独立知识产权及著作权
            </p>
            <p className="copy-right">
                Copyright ©2019-2020 FINCOM Corporation, All Rights Reserved
            </p>
        </footer>
    );
};
export default Footer;
