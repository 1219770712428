/**
 * content-type
 *
 * @enum {CONTENT_TYPE}
 */
enum CONTENT_TYPE {
    JSON = "application/json;",
    FORM = "application/x-www-form-urlencoded;",
    UPLOAD_FILE = "multipart/form-data;",
}

/**
 * content-type
 *
 * @enum {DATA_TYPE}
 */
enum DATA_TYPE {
    JSON = "json",
    JSONP = "jsonp",
    TEXT = "text",
    SCRIPT = "script",
}

/**
 * charset 字符集编码方式
 *
 * @enum {CHARSET}
 */
enum CHARSET {
    UTF8 = "charset=utf-8",
}

/**
 * 请求方法
 *
 * @enum {number}
 */
enum METHOD {
    GET = "GET",
    POST = "POST",
}

/**
 * 模式
 * cor 表示同域和带有CORS响应头的跨域下可请求成功. 其他请求将被拒绝.
 * same-origin: 表示同域下可请求成功; 反之, 浏览器将拒绝发送本次fetch, 同时抛出错误 “TypeError: Failed to fetch(…)”.
 * cors-with-forced-preflight: 表示在发出请求前, 将执行preflight检查.
 * no-cors: 常用于跨域请求不带CORS响应头场景, 此时响应类型为 “opaque”.
 * @enum {MODE}
 */
enum MODE {
    SAME = "same-origin",
    NAVIGATE = "navigate",
    NO_CORS = "no-cors",
    CORS = "cors",
}

/**
 * cookie设置
 * include 请求带上cookies，使每次请求保持会话一致
 * omit: 缺省值, 默认为该值.
 * same-origin: 同源, 表示同域请求才发送cookie.
 * @enum {CREDENTIAL}
 */
enum CREDENTIAL {
    INCLUDE = "include",
    OMIT = "omit",
    SAME = "same-origin",
}

/**
 * 处理缓存
 * default: 表示fetch请求之前将检查下http的缓存.
 * no-store: 表示fetch请求将完全忽略http缓存的存在. 这意味着请求之前将不再检查下http的缓存, 拿到响应后, 它也不会更新http缓存.
 * no-cache: 如果存在缓存, 那么fetch将发送一个条件查询request和一个正常的request, 拿到响应后, 它会更新http缓存.
 * reload: 表示fetch请求之前将忽略http缓存的存在, 但是请求拿到响应后, 它将主动更新http缓存.
 * force-cache: 表示fetch请求不顾一切的依赖缓存, 即使缓存过期了, 它依然从缓存中读取. 除非没有任何缓存, 那么它将发送一个正常的request.
 * only-if-cached: 表示fetch请求不顾一切的依赖缓存, 即使缓存过期了, 它依然从缓存中读取. 如果没有缓存, 它将抛出网络错误(该设置只在mode为”same-origin”时有效).
 * @enum {number}
 */
enum CACHE_ENUM {
    DEFAULT = "default",
    NO_STORE = "no-store",
    RELOAD = "reload",
    NO_CACHE = "no-cache",
    FORCE_CACHE = "force-cache",
    ONLY_CACHE = "only-if-cached",
}

export {
    CONTENT_TYPE,
    CHARSET,
    METHOD,
    MODE,
    CREDENTIAL,
    CACHE_ENUM,
    DATA_TYPE,
};
