import React from "react";
import { CSSTransition } from "react-transition-group";
import BaseComponent from "./BaseComponent";

import "./Animate.scss";

export enum ANIMATION_TYPE {
    FADE = "fade",
    SLIDE_UP = "slide-up",
    SLIDE_RIGHT = "slide-right",
    SLIDE_LEFT = "slide-left",
    EXPAND = "expand-in",
}

interface IProps {
    animation: ANIMATION_TYPE;
    duration: number;
}

interface IState {
    in: boolean;
}

export default class Animate extends BaseComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            in: false,
        };
    }

    static defaultProps = {
        animation: ANIMATION_TYPE.FADE,
        duration: 300,
    };

    componentWillMount(): void {
        this.setState({
            in: true,
        });
    }

    render() {
        const { children, duration, animation } = this.props;

        return (
            <CSSTransition
                classNames={animation}
                in={this.state.in}
                timeout={duration}
                unmountOnExit
            >
                {children}
            </CSSTransition>
        );
    }
}
