import React from "react";
import BaseComponent from "../../ui/BaseComponent";
import PluginHttp from "../../http/PluginHttp";
import Model from "../../ui/Model";
import Popup from "../../ui/Popup";
import Config from "../../utils/Config";

interface IProps {
    className?: string;
    callback?: string;
    selfRedirect?: boolean;
    type: "user" | "student" | "login";
    id?: string;
    menu?: "userList" | "studentList";
}

export default class WXLogin extends BaseComponent<IProps, any> {
    static defaultProps = {
        callback: "wechatwx/authCallBack",
        selfRedirect: false,
        type: "login",
    };

    state = {
        message: "",
    };

    componentDidMount() {
        this.getOneKey();
    }

    getOneKey = () => {
        const { type, id, menu } = this.props;

        PluginHttp.ajax({
            url: Config.serverUrl + "/getOneKey",
            headers: {
                type,
                id,
                menu,
            },
        })
            .then((data: any) => {
                if (data.returnCode === "10000") {
                    this.getQRCode(data.oneKey);
                } else {
                    // message.error(data.returnDesc || "网络异常，请稍候再试");
                    this.setState({
                        message: data.returnDesc || "网络异常，请稍候再试",
                    });
                }
            })
            .catch((e: Error) => {});
    };

    getQRCode = (state: string) => {
        console.log("-------getQRCode------", state);
        const { callback, selfRedirect } = this.props;
        const WxLogin: any = (window as any).WxLogin;

        new WxLogin({
            self_redirect: selfRedirect,
            id: "qrcode",
            appid: "wx691f46345fac2550",
            // appid: "wx9a7c0f9cfb6a15f5",
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(
                Config.wxLoginCallbackServer + callback
            ),
            state,
            href:
                "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE5MHB4O21hcmdpbi10b3A6IDA7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTkwcHg7fQ0KLmltcG93ZXJCb3ggLmljb24zOF9tc2cuc3VjYyB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5pY29uMzhfbXNnLndhcm4ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fQ==",
        });
    };

    handlePopupClick = () => {
        this.setState({
            message: "",
        });
    };

    render() {
        const { className } = this.props;

        return (
            <div className={`qrcode ${className}`}>
                <div id="qrcode" />

                {this.state.message ? (
                    <Model>
                        <Popup
                            title="失败提醒"
                            buttons={["确认"]}
                            onButtonListener={this.handlePopupClick}
                            content={this.state.message}
                        />
                    </Model>
                ) : null}
            </div>
        );
    }
}
