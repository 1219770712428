import React, { SFC } from "react";
import Card from "./Card";
import AlVideoPlayer from "../../common/AlVideoPlayer";

import "./AssessList.scss";

interface IProps {
    data: any;
}

const AssessList: SFC<IProps> = ({ data }: IProps) => {
    console.log(data, "dasfdasdfafs");
    if (!data || !data.inviteType) {
        return null;
    }
    if (data.inviteType === "1") {
        return (
            <Card title="" className="assess-list">
                {data.inviteDetail}
            </Card>
        );
    }

    return (
        <div
            className="assess-list"
            style={{
                height: "208px",
                background: "#fff",
            }}
        >
            <AlVideoPlayer data={data} />
        </div>
    );
};

export default AssessList;
