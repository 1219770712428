import PluginHttp from "../../../http/PluginHttp";
import Config from "../../../utils/Config";

interface IOption {
    timeout: number;
    onUploadProgress: (
        uploadInfo: any,
        totalSize: number,
        progress: number
    ) => void;
    onUploadSucceed?: (uploadInfo: any) => void;
    onUploadFailed?: (uploadInfo: any) => void;
}

export default function createAliOssUpload(options: IOption) {
    const { timeout, onUploadProgress, onUploadSucceed } = options;
    const VODUpload = (window as any).AliyunUpload.Vod;
    const uploader = new VODUpload({
        timeout,
        // 添加文件成功
        addFileSuccess: function (uploadInfo: any) {
            console.log("addFileSuccess" + JSON.stringify(uploadInfo));
        },
        // 开始上传
        onUploadstarted: function (uploadInfo: any) {
            console.log("文件开始上传...");
            console.log(
                "onUploadStarted:",
                uploadInfo,
                JSON.stringify(uploadInfo)
            );

            const { name, type } = uploadInfo.file;
            const typeMap: any = {
                image: "img",
                video: "video",
                audio: "audio",
            };
            const nameArr: string[] = name.split(".");
            const typeArr: string[] = type.split("/");

            // console.log('videoSuffix', config.serverUrl + '/getVideoSignature', urlArr[urlArr.length - 1])
            PluginHttp.ajax({
                url: Config.serverUrl + "/getVideoSignature",
                headers: {
                    videoUsage: "assess", // common  通用素材，指课程素材,assess 评价素材
                    videoType: typeMap[typeArr[0]],
                    videoSuffix: nameArr[1],
                },
            })
                .then((data) => {
                    if (data.returnCode === "10000") {
                        // console.log('data', uploadInfo, data.uploadAuth, data.uploadAddress, data.aliVideoId);
                        uploadInfo.vid = data.videoId;
                        uploadInfo.videoType = typeMap[typeArr[0]];
                        uploader.setUploadAuthAndAddress(
                            uploadInfo,
                            data.UploadAuth,
                            data.uploadAddress,
                            data.aliVideoId
                        );
                    } else {
                        console.log(data);
                        uploader.stopUpload();
                    }
                })
                .catch((e: Error) => {
                    uploader.stopUpload();
                    console.log(e);
                });
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo: any) {
            console.log(JSON.stringify(uploadInfo));
            console.log("文件上传成功!");
            onUploadSucceed && onUploadSucceed(uploadInfo);
        },
        // 文件上传失败
        onUploadFailed: function (
            uploadInfo: any,
            code: number,
            message: string
        ) {
            console.log(
                "onUploadFailed: file:" +
                    uploadInfo.file.name +
                    ",code:" +
                    code +
                    ", message:" +
                    message
            );
            console.log("文件上传失败!");
            uploader.stopUpload();
        },
        // 取消文件上传
        onUploadCanceled: function (
            uploadInfo: any,
            code: number,
            message: string
        ) {
            console.log(JSON.stringify(uploadInfo) + code + message);
            console.log("文件已暂停上传!");
            uploader.stopUpload();
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (
            uploadInfo: any,
            totalSize: number,
            progress: number
        ) {
            onUploadProgress &&
                onUploadProgress(uploadInfo, totalSize, progress);
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo: any) {
            // // 如果是上传方式二即根据 STSToken 实现时，从新获取STS临时账号用于恢复上传
            // // 上传文件过大时可能在上传过程中 sts token 就会失效, 所以需要在 token 过期的回调中调用 resumeUploadWithSTSToken 方法
            // // 这里是测试接口, 所以我直接获取了 STSToken
            // $('#status').text('文件上传超时!')
            //
            // var stsUrl = 'http://demo-vod.cn-shanghai.aliyuncs.com/voddemo/CreateSecurityToken?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=67999yyuuuy&AppVersion=1.0.0'
            // $.get(stsUrl, function (data) {
            //     var info = data.SecurityTokenInfo
            //     var accessKeyId = info.AccessKeyId
            //     var accessKeySecret = info.AccessKeySecret
            //     var secretToken = info.SecurityToken
            //     var expiration = info.Expiration
            //     uploader.resumeUploadWithSTSToken(accessKeyId, accessKeySecret, secretToken, expiration)
            // }, 'json')
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo: any) {
            console.log("文件上传完毕 onUploadEnd: uploaded all the files");
        },
    });

    return uploader;
}
