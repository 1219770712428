import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";
import Flex from "../../../ui/Flex";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";
import CONSULT_IMG from "./img/consult.png";

const Lab: FunctionComponent<{}> = () => {
    return (
        <Layout
            background={BACKGROUND_IMG}
            title="咨询预约"
            className="consult"
        >
            <p>
                <span>测试预约：</span>
                每一位申请成为实验室的会员， 需提前预约试绘能力测试，
                通过几项测评了解申 请人目前的绘画情况，
                评估是否适合参与实验室项目。
            </p>
            <p>
                如需了解更多有关试绘测试、会员费、 认证画室、
                学术艺术合作等问题， 请联系我们。
            </p>

            <Flex className="consult-code" ratio={[0, 1]}>
                <img src={CONSULT_IMG} alt="咨询" />
                <div className="consult-tip">
                    <div className="member-info">
                        2021 年秋季会员招募已启动， 本季招募年龄为 7-12 岁
                    </div>
                    <div className="online-time">工作时间：9:30-17:30</div>
                </div>
            </Flex>
        </Layout>
    );
};

export default Lab;
