import React from "react";
import Flex, { FlexConst } from "../../../ui/Flex";
import { Link } from "react-router-dom";

import "./Header.scss";

import SLOGAN_IMG from "./img/slogan.png";

interface IProps {
    logo?: boolean;
    background: string;
    title?: string;
}

interface IMenu {
    name: string;
    route: string;
}

const INDEX_MENUS: Array<IMenu> = [
    {
        name: "首页",
        route: "/official/index",
    },
    {
        name: "艺术脚本",
        route: "/official/research",
    },
    {
        name: "作品",
        route: "/official/gallery",
    },
    {
        name: "咨询",
        route: "/official/consult",
    },
    {
        name: "认证画室",
        route: "/official/studio",
    },
    {
        name: "声明文件",
        route: "/official/declare",
    },
];

const Header: React.FunctionComponent<IProps> = ({
    background,
    title,
}: IProps) => {
    return (
        <header className="ui-official-header">
            <Flex
                className="head-in"
                ratio={[1, 1]}
                cross={FlexConst.cross.top}
                style={{ backgroundImage: `url(${background})` }}
            >
                <div className="slogan">
                    <img src={SLOGAN_IMG} alt="" />
                </div>
                <div className="logo">搭建优秀艺术思维</div>
            </Flex>

            <div className={`menu`}>
                <div className="menu-wrap">
                    {INDEX_MENUS.map((item: IMenu) => {
                        return (
                            <Link to={item.route} key={item.route}>
                                {item.name}
                            </Link>
                        );
                    })}
                </div>
            </div>

            {title ? (
                <div className="head-title">
                    <h2>{title}</h2>
                    <div className="head-title-arrow" />
                </div>
            ) : null}
        </header>
    );
};
export default Header;
