import React from "react";
import { match } from "react-router-dom";
import { History } from "history";
import BaseComponent from "../../ui/BaseComponent";
import Layout from "../common/Layout";
import Model from "../../ui/Model";
import { ICourseInfo } from "./ui/interface";
import PluginHttp, { HTTP_STATE } from "../../http/PluginHttp";
import ClassList from "./ui/ClassList";
import Loading from "../../ui/Loading";
import Url from "./Const";

import "./Course.scss";
import Popup from "../../ui/Popup";

interface IProps {
    match: match & {
        params: {
            source: string;
        };
    };
    history: History;
}

interface IState {
    loading: HTTP_STATE;
    courseList: Array<ICourseInfo>;

    message: string;
}

export default class CourseList extends BaseComponent<IProps, IState> {
    state = {
        loading: 1,
        // 课程列表
        courseList: [],

        // 通用提示弹框
        message: "",
    };

    componentDidMount() {
        // console.log("this.props", this.props);
        this.loadList();
    }

    loadList = () => {
        PluginHttp.ajax({
            url: Url.COURSE_LIST,
            headers: {
                source: this.props.match.params.source,
            },
        })
            .then((listData: any) => {
                if (listData.returnCode === "40220") {
                    this.setState({
                        message: "您还没有登录，快去登录吧",
                    });
                    return;
                } else if (listData.returnCode !== "10000") {
                    throw new Error(listData.returnDesc);
                }

                if (!listData.classList || listData.classList.length === 0) {
                    this.setState({
                        message: "您当前没有可上课程",
                    });
                    return;
                }

                this.setState({
                    loading: 2,
                    courseList: listData.classList,
                });
            })
            .catch((e: Error) => {
                // console.log("-----------e---------", e);
                this.setState({
                    loading: 3,
                });
            });
    };

    selectCourse = (course: ICourseInfo, index: number) => {
        const { history } = this.props;
        history.push(`/wechatweb/course/${course.classId}`);
    };

    render() {
        const { courseList, loading } = this.state;

        return (
            <Layout className="page-course">
                <Loading state={loading}>
                    {/* 课程列表弹框 */}
                    <Model className="course-list-model">
                        {courseList && courseList.length ? (
                            <ClassList
                                data={courseList}
                                onClick={this.selectCourse}
                            />
                        ) : null}
                    </Model>
                </Loading>

                {this.state.message ? (
                    <Model>
                        <Popup
                            buttons={["确认"]}
                            content={this.state.message}
                            onButtonListener={() => {
                                this.props.history.push("/wechatweb/index");
                            }}
                        />
                    </Model>
                ) : null}
            </Layout>
        );
    }
}
