import React, { FunctionComponent } from "react";
import Layout from "../common/Layout";
import Flex from "../../../ui/Flex";

import "./index.scss";
import BACKGROUND_IMG from "./img/head_bg.png";

const STUADIO_LIST = [
    "美国波士顿画室：波士顿市区",
    "北京清华大学画室：清华大学校内新林院",
    "北京翡翠山画室：近王佐、南宫、云岗、长阳",
    "北京燕西画室：近人大附中丰台学校、青龙湖",
    "北京西山湖画室：近中央民族大学新校区",
    "北京朝阳路画室：近东四环慈云寺桥",
    "北京北印画室：北京印刷学院校内产业园",
    "广州白云区第一画室：近岭南新世界",
];

const Lab: FunctionComponent<{}> = () => {
    return (
        <Layout
            background={BACKGROUND_IMG}
            title="认证画室"
            className="author-studio"
        >
            <Flex className="studio-info" ratio={[0, 1]}>
                <div className="logo" />

                <div className="studio-list">
                    {STUADIO_LIST.map((text: string, index: number) => {
                        return <p key={index}> {text}</p>;
                    })}
                </div>
            </Flex>
        </Layout>
    );
};

export default Lab;
