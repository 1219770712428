import React from "react";
import { match } from "react-router-dom";
import { History } from "history";
import BaseComponent from "../../ui/BaseComponent";
import Rem from "../../utils/Rem";
import Cookie from '../../utils/Cookie';

import "./index.scss";

interface IProps {
    match: match & {
        params: {
            key: string;
        };
    };
    history: History;
}

export default class Assess extends BaseComponent<IProps, any> {
    state = {
        returnCode: Cookie.get("returnCode")
    }

    componentDidMount():void {
        Cookie.set("returnCode", "", { path: "/" });
    }


    render() {
        const {returnCode} = this.state;

        return (
            <Rem>
                <div className="ui-page">
                    <div className="page-head">
                        <span className="title">UAD</span>
                        {/* <span className="sys">评语端 Comment</span> */}
                    </div>
                    <div className="page-body" style={{textAlign: 'center', marginTop: '.3rem'}}>
                        {returnCode === "10000"? "微信绑定成功": Cookie.get("returnDesc") || "微信绑定失败"}
                    </div>
                    <div className="page-foot">UAD 艺术实验室 版本1.0</div>
                </div>
            </Rem>
        );
    }
}
