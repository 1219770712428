import React, { SFC } from "react";
import Card from "../../common/Card";
import { ICourseInfo } from "./interface";

import "./InfoCard.scss";

interface IProps {
    title: string;
    data: ICourseInfo;
    time?: number;
}

const InfoCard: SFC<IProps> = ({ title, data, time }: IProps) => {
    return (
        <Card
            title={title}
            // desc={timeFormat(new Date(time), "yyyy-MM-dd hh:mm")}
            time={time}
            className="info"
        >
            <ul>
                <li>
                    <span className="name">实验编号</span>
                    <span className="value">{data.courseId}</span>
                </li>
                <li>
                    <span className="name">实验名称</span>
                    <span className="value">{data.courseName}</span>
                </li>
                <li>
                    <span className="name">实验类别</span>
                    <span className="value">{data.courseType}</span>
                </li>
                <li>
                    <span className="name">实验时限</span>
                    <span className="value">
                        {data.classTimes ? (
                            <React.Fragment>{data.classTimes}</React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span>{data.classBeginTime}</span>
                                <span>{data.classEndTime}</span>
                            </React.Fragment>
                        )}
                    </span>
                </li>
            </ul>
        </Card>
    );
};

export default InfoCard;
