import React, { SFC } from "react";
import Timer from "../../ui/Timer";
import "./Card.scss";

interface IProps {
    className?: string;
    title?: string;
    desc?: string;
    children: React.ReactNode;
    time?: number;
}

const Card: SFC<IProps> = ({
    className,
    title,
    desc,
    children,
    time,
}: IProps) => {
    return (
        <div className={`ui-card ${className}`}>
            {title || desc ? (
                <div className="ui-card-head">
                    <h3>{title}</h3>
                    {desc ? <p>{desc}</p> : null}
                    {time ? <Timer time={time} /> : null}
                </div>
            ) : null}

            <div className="ui-card-body">{children}</div>
        </div>
    );
};

export default Card;
